r
<template>
  <div class="grid-x">
    <div class="cell medium-6">
      <card-item :title="$t('interface.font')" icon="icon-interface-font">
        <div style="color:#cecece; margin-bottom:30px;">{{ $t('interface.fonts_not_editable') }}</div>
        <ab-input-field
          :label="$t('interface.font_library')"
          :readonly="true"
          v-model="settings.font_library"
        ></ab-input-field>
        <ab-input-field
          :label="$t('interface.font_family')"
          :readonly="true"
          v-model="settings.font_family"
        ></ab-input-field>
      </card-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterfaceSettingsFonts',
  props: ['content'],
  computed: {
    settings: {
      get () {
        return this.content
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
